import React, {useEffect, useState, useRef} from "react"
import { graphql } from "gatsby"
import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image"
import _ from "underscore"
import * as printSound from "/static/printsound.mp3"
import * as beep from "/static/short-beep.mp3"
import Draggable from 'react-draggable';
import * as longsoundaudio from "/static/alarm.mp3"
import "./greetings.scss"
let sound,beepSound,longsound;
//hen update audio by adding audio area, uncommenting callback and filling mp3

var codes = {
  "89501": "business-penmanship",
  "01234": "alphabet",
  "50023": "happy-hen-house",
  "73809": "lemons",
  "00001": "index",
  "39481": "secretarial-training",
  "00002": "matthew-marks-gallery-press-release",
  "00911": "history-of-home-alarm-systems",
  "89505": "guestbook-gifs",
  "00264": "sound",
  "20022": "mermaid",
}

let faxInput = "";

const FaxQueue = ({currentPage,currentBook,books,children}) => {
  let pages = books[currentBook];
  if(!pages)
    return <div id="scanner-queue" className={currentPage}></div>
  return <div id="scanner-queue" className={currentBook}>
  {pages.map((page,i) => {
    const el = (<div key={currentBook+i} className={`greetings-page-wrapper ${i===currentPage ? "printing" : ""} ${i<=currentPage ? "printed" : ""} page-in-${currentBook}`}>
      <GatsbyImage loading="lazy" image={getImage(page)} alt=""/>
    </div>)
    return el;
  }
  )}
  </div>
}

const FaxReadout = ({code}) => {
  var digits = []
  var theCode = code.length >= 5 ? code.slice(code.length - 5) : code;
  for (var i = 0; i < theCode.length; i++) {
    var offset = 4 + (theCode[i] === "0" ? 90 : 10*(parseInt(theCode[i]) - 1))
    digits.push(<span key={i} style={{backgroundPositionX: offset+"%"}} className={`greetings-digit digit-${theCode[i]}`}></span>);
  }
  return <div id="fax-readout">{digits}</div>
}

const Printed = ({pages,books}) => {
  let currentBook = pages[0];
  let currentBookPage = 0;
  return <div id="printed">
    { pages.current && books[pages.current[0]]?
      pages.current.map((page,i) => {
        if(currentBook !== page){
          currentBook = page;
          currentBookPage = 0;
        }
        console.log("CURRENT",currentBook)
        let cropBottom = currentBook === "guestbook-gifs"
        let gbImgData = books[page][currentBookPage++].gatsbyImageData;
        let ar = gbImgData.height/gbImgData.width;
        return <div key={i} className="printed-page" style={{zIndex: 1+i, height: 60*ar+"%"}}>
          {books[page][currentBookPage].gatsbyImageData ?
            <img srcSet={gbImgData.images.fallback.srcSet} alt=""></img> : ""
          }
        </div>
      }) : []
    }
  </div>;
}

export default function GreetingsPage({data}){
  let [postits,setPostits] = useState([])
  let [mobileInput,setMobileInput] = useState("")
  let books = {}
  data.greetings.group.forEach((g) => {
    books[g.tag.split("/")[2]] = g.edges.sort((a,b) => a.node.relativePath > b.node.relativePath ? 1 : -1)
    .map(e => e.node.childImageSharp ? e.node.childImageSharp : e.node.publicURL )
  })
  let [showDryErase,setShowDryErase] = useState(false);
  let [imageSize,setImageSize] = useState([2000,2269])
  let [fax,setMyFax] = useState("")
  let [book,setMyBook] = useState("")
  let [audioPlaying,setAudioPlaying] = useState("")
  let [pages,setThePages] = useState([])
  let [printing,setPrinting] = useState(false)
  const faxRef = useRef("");
  const bookRef = useRef("");
  const pagesRef = useRef([]);
  const setBook = data => {
    bookRef.current = data;
    setMyBook(data);
  }
  const setPages = _.throttle(data => {
    pagesRef.current = data;
    sound = new Audio(printSound.default);
   if(sound && pagesRef.current[0] !== ""){
     sound.play();
     setPrinting(true);
     setTimeout(() => {
       sound.pause();
       sound.currentTime = 0;
       setPrinting(false);
     },5000)
   }
   setThePages(data);
 },5000);
  const setFax = data => {
   faxRef.current = data;
   setMyFax(data);
  };
  const faxHandler = (button) => {
    let faxInput = faxRef.current.slice(-5);
    let pagesPrinted = pagesRef.current;
    let book = bookRef.current;
    let printSuccess = false;
    if(button === "stop"){
      // longsound.currentTime = 0;
      if(longsound.paused){
        longsound.play();
      } else {
        longsound.pause();
      }
      return;
    }
    if(button === "print"){
      console.log("attempting to print: ",faxRef.current.length, faxRef.current.slice(-5))
      if(codes[faxInput]){ //If we just started a new book
        setBook(codes[faxInput])
        setFax("");
        setPages(pagesPrinted.concat([codes[faxInput]]))
        printSuccess = true;
      } else {
        console.log("well we will try to print ",book)
        if(book !== ""){
          setPages(pagesPrinted.concat([book]))
          printSuccess = true;
        } else {
          beepSound.currentTime = 0;
          beepSound.play();
        }
      }

    } else {
      if (button == "backspace") {
        setFax((faxInput ? faxInput.slice(0,faxInput.length-1) : ""));
      } else {
        setFax((faxInput ? faxInput : "") + button);
      }
      if(beepSound){
        console.log("play!",beepSound)
        beepSound.play();
        beepSound = new Audio(beep.default);
        // setTimeout(()=>{beepSound.play();},1)

      }
    }
  }
  useEffect((e) =>{
    if(typeof window != "undefined"){
      beepSound = new Audio(beep.default);
      longsound = new Audio(longsoundaudio.default);
      setTimeout(function(){
        var wrapperElement = document.getElementById("greetings-page")
        setImageSize([wrapperElement.offsetWidth,wrapperElement.offsetHeight])
      },500)
      var wrapperElement = document.getElementById("greetings-page")
      setImageSize([wrapperElement.offsetWidth,wrapperElement.offsetHeight])
      window.onresize = function(e){
        setImageSize([wrapperElement.offsetWidth,wrapperElement.offsetHeight])
      }
    }
  },[])
  const onStart = (e) => {
    e.preventDefault()
    e.stopPropagation()
    console.log("started!!",e)
  }
  const buttonClick = (e) => {
    setShowDryErase(false);
    let name = e.target.getAttribute("btn-name");
    faxHandler(name)
  }
  const scrollToFaxMobile = (e) => {
    var fax = document.querySelector("#fax")
    let rect = fax.getBoundingClientRect()
    document.documentElement.scrollTop = rect.y-90
  }
  const onMobileChange = (e) => {
    setShowDryErase(false);
    let v = e.target.value;
    if(v.length < mobileInput.length){
      faxHandler("backspace");
    } else {
      faxHandler(v.slice(-1));
    }
    setMobileInput(v);
  }
  const mobileRevealDryErase = (e) => {
    if(window.innerWidth<480){
      setShowDryErase(!showDryErase);
    }
  }
  const mobileHideDryErase = (e) => {
    if(window.innerWidth<480){
      setShowDryErase(false);
    }
  }
  const numberButtons = () => {
    let divs = []
    for (var i = 0; i < 10; i++) {
      divs.push(<div key={i} onClick={buttonClick} className="number-button" btn-name={(i+1)%10} id={`num-${i+1}`}></div>);
    }
    return divs;
  }
  const dragStart = (e) => {
    console.log(e.target)
    let el = e.target;
    el.classList.add("dragging")
  }
  const dragPosts = (e) => {

  }
  const dragStop = (e) => {
    console.log(console.log("ended"))
  }
  const randomColor = () => ["#E8DE33","#EF6479","#55BDB9"][Math.floor(Math.random()*3)];
  let colorArray = ["#ECE298","#8CCFBB","#FF8098"]
  const addPostit = () => {
    let current = postits.slice();
    let postCount = current.length;
    console.log(current);
    let style = {
      backgroundColor: `${colorArray[current.length%colorArray.length]}`,
      zIndex: current.length
    }
    current.unshift(
      <Draggable key={current.length}
      onStart={onStart}
      onMouseDown={onStart}
      onClick={onStart}
      onStop={dragStop}
      defaultPosition={{x: 0, y: 0}}
      position={null}><div className="postit" style={style}></div>
    </Draggable>)
    setPostits(current);
  }
  return <div id="greetings-page" className={printing ? "printing" : ""}>
    <div className="page-bg">
      <StaticImage objectPosition="50% 0%"  src={"../images/fax/background.jpg"} alt=""/>
    </div>
    <div id="dry-erase" onClick={mobileRevealDryErase} className={showDryErase ? "show" : ""}>
      <StaticImage object-fit="contain" layout={"fullWidth"} src={"../images/fax/dryerase3-crop.png"} alt=""/>
    </div>
    <div className="table-top" onClick={mobileHideDryErase}>
      <div id="fax-table" onClick={mobileHideDryErase}>
        {/*<StaticImage layout={"fullWidth"} src={"../images/fax/table.png"} alt=""/>*/}
      </div>
        <div id="fax" onClick={mobileHideDryErase}>
          <div id="fax-bg"><StaticImage src={"../images/fax/fax.png"} alt=""/></div>
          <div id="numbers-container">
          <input onChange={onMobileChange} onClick={scrollToFaxMobile} inputMode="numeric" pattern="[0-9]*" type="text" className="mobile-numpad"></input>
            <div id="numbers">{numberButtons()}</div>
          </div>
          <div id="controls">
            <div onClick={buttonClick} id="print-button" btn-name="print"></div>
            <div onClick={buttonClick} id="stop-button" btn-name="stop"></div>
          </div>
          <Printed books={books} pages={pagesRef}></Printed>
          <FaxReadout code={fax}/>
        </div>
        <div id="postits" onMouseDown={addPostit} onDrag={dragPosts}>
          <div id="postit-stack">
            <StaticImage src={"../images/fax/postits.png"} alt=""/>
          </div>
          {postits}
        </div>
      </div>
  </div>
}
export const query = graphql`
query GetGreetings {
  file(relativePath: { eq: "fax2.jpg" }) {
    publicURL
    childImageSharp {
      gatsbyImageData
    }
  }
  greetings: allFile(filter: {relativeDirectory: {glob: "tables/greetings/*"}}) {
    group(field: relativeDirectory) {
      tag: fieldValue
      edges {
        node {
          id
          publicURL
          childImageSharp {
            gatsbyImageData
          }
          relativePath
        }
      }
    }
  }
}
`
